<template>
  <div>
    <el-dialog title="选择题目"
               v-if="dialogVisible"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="1200px"
               :before-close="handleClose">
      <checkTable ref="checkTableRef"
                  :url="'/microlecture/list'"
                  :isComponent="true"
                  @checkItem="checkItem" />
    </el-dialog>
  </div>
</template>

<script>
import checkTable from './topicList.vue'
export default {
  data () {
    return {
      dialogVisible: false,
      // 用来存储新的题目信息
      info: {},
      innerVisible: false,
      // 用来存储老的微课数据
      form: {}
    }
  },
  components: {
    checkTable
  },
  methods: {
    handleClose () {
      this.innerVisible = false
      this.dialogVisible = false
    },
    checkItem (item) {
      this.info = item
      this.save()
    },
    save () {
      let form = {
        id: this.form.id,
        question_id: this.info.question_id,
        source_id: this.info.source_id,
        subject_id: this.info.subject_id,
        description: this.form.description
      }
      this.$http({
        url: '/api/v1/course/edit',
        method: 'post',
        data: form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '修改成功!',
          type: 'success'
        });
        this.handleClose()
        this.$parent.search()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

::v-deep .el-table__body tr.current-row > td {
  background: #d8eaff !important;
}
</style>